<template>
  <!-- Outer container / design wrapper -->
  <div class="bg-white py-4 sm:py-2">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">

      <!-- Header Section -->
      <div class="mx-auto max-w-4xl text-center">
        <p
          class="mt-2 text-balance text-2xl font-semibold tracking-tight text-gray-900 sm:text-2xl"
        >
          {{ $t('publicArea.registerCompany.steps.websiteSubscription.title') }}
        </p>
      </div>

      <div
        class="mx-auto mt-6 max-w-2xl text-pretty text-center text-sm font-normal text-gray-600 sm:text-lg/12 mb-12"
      >
        {{ $t('publicArea.registerCompany.steps.websiteSubscription.description') }}
        <p class="mt-2 text-indigo-600 underline">
          <a href="https://firstmedia.swiss/" target="_blank">
            <img
              class="w-72 block mx-auto"
              src="../../../../assets/images/first-media/firstmediasolutions-black.png"
              alt="First Media Swiss"
            />
          </a>
        </p>
      </div>


      <!-- billingCycle Toggle -->
      <div class="mt-6 flex justify-center">
        <fieldset aria-label="Payment billingCycle">
          <RadioGroup
            v-model="billingCycle"
            class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200"
          >
            <RadioGroupOption
              as="template"
              v-for="option in frequencies"
              :key="option.value"
              :value="option"
              v-slot="{ checked }"
            >
              <div
                :class="[
                  checked ? 'bg-gray-800 text-white' : 'text-gray-600',
                  'cursor-pointer rounded-full px-2.5 py-1'
                ]"
              >
                {{ option.label }}
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </fieldset>
      </div>

      <!-- Pricing Tiers (3 columns) -->
      <div
        class="isolate mx-auto mt-6 grid max-w-md grid-cols-1 gap-8
               lg:mx-0 lg:max-w-none lg:grid-cols-3"
      >
        <!-- STARTER Plan -->
        <div
          @click="choosePlan('starter')"
          class="cursor-pointer rounded-3xl p-8 ring-1 ring-gray-200 flex flex-col"
          :class="{
            'border-gray-500 ring-gray-500': selectedPlan === 'starter'
          }"
        >
          <h3 class="text-lg font-semibold text-gray-900">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.starter') }}
          </h3>
          <p class="mt-4 text-sm text-gray-600">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.starterDescription') }}
          </p>
          <!-- Pricing -->
          <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-semibold tracking-tight text-gray-900">
              <span v-if="billingCycle.value === 'monthly'">9.90 CHF</span>
              <span v-else>100.95 CHF</span>
            </span>
            <span class="text-sm font-semibold text-gray-600">
              / {{ billingCycle.value === 'monthly' ? $t('general.month') : $t('general.year') }}
            </span>
          </p>
          <!-- Discount note if annually -->
          <p
            v-if="billingCycle.value === 'annually'"
            class="text-sm text-green-600"
          >
            ({{ $t('publicArea.registerCompany.steps.websiteSubscription.fifteenPercentOff') }})
          </p>
          <!-- Button or CTA -->
          <btn
            @click="submitSubscription('starter')"
            class="mt-6 block rounded-md bg-gray-900
                   px-3 py-2 text-center text-sm font-semibold text-white
                   hover:bg-gray-800"
          >
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.buyNowBtn') }}*
          </btn>
          <!-- Feature list -->
          <ul class="mt-8 space-y-3 text-sm text-gray-600">
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureCHDomain') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureStandardHosting') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureUpdates') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureOnePageSite') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureLogoAndColor') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSimpleForm') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSupportEmail') }}
            </li>
          </ul>
          <p class="mt-8 text-indigo-600 underline">
            <a href="https://app.firstmatic.ch/v2/preview/uxqzVaB5Wplg87vr5Vkb?notrack=true" target="_blank">
              Starter Beispiel-Website
            </a>
          </p>
        </div>

        <!-- STANDARD Plan -->
        <div
          @click="choosePlan('standard')"
          class="cursor-pointer rounded-3xl p-8 ring-1 ring-gray-200 flex flex-col"
          :class="{
            'border-gray-500 ring-gray-500': selectedPlan === 'standard'
          }"
        >
          <h3 class="text-lg font-semibold text-gray-900">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.standard') }}
          </h3>
          <p class="mt-4 text-sm text-gray-600">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.standardDescription') }}
          </p>
          <!-- Pricing -->
          <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-semibold tracking-tight text-gray-900">
              <span v-if="billingCycle.value === 'monthly'">29.90 CHF</span>
              <span v-else>304.95 CHF</span>
            </span>
            <span class="text-sm font-semibold text-gray-600">
              / {{ billingCycle.value === 'monthly' ? $t('general.month') : $t('general.year') }}
            </span>
          </p>
          <p class="flex items-center mt-1 text-xs text-gray-600">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.minDuration') }}
          </p>
          <!-- Discount note if annually -->
          <p
            v-if="billingCycle.value === 'annually'"
            class="text-sm text-green-600"
          >
            ({{ $t('publicArea.registerCompany.steps.websiteSubscription.fifteenPercentOff') }})
          </p>
          <!-- Button or CTA -->
          <btn
            @click="submitSubscription('standard')"
            class="mt-6 block rounded-md bg-gray-900
                   px-3 py-2 text-center text-sm font-semibold text-white
                   hover:bg-gray-800"
          >
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.buyNowBtn') }}*
          </btn>

          <!-- Feature list -->
          <ul class="mt-8 space-y-3 text-sm text-gray-600">
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureCHDomain') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureOwnEmail') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featurePremiumHosting') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureUpdates') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureChooseDesign') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureMax5Pages') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSEOTexte') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureQuoteForm') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSupportEmail') }}
            </li>
          </ul>
          <p class="mt-8 text-indigo-600 underline">
            <a href="https://ps-umzug.ch" target="_blank">
              Standard Beispiel-Website
            </a>
          </p>
        </div>

        <!-- PROFESSIONAL Plan -->
        <div
          @click="choosePlan('professional')"
          class="cursor-pointer rounded-3xl p-8 ring-1 ring-gray-200 flex flex-col"
          :class="{
            'border-gray-500 ring-gray-500': selectedPlan === 'professional'
          }"
        >
          <h3 class="text-lg font-semibold text-gray-900">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.professional') }}
          </h3>
          <p class="mt-4 text-sm text-gray-600">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.professionalDescription') }}
          </p>
          <!-- Pricing -->
          <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-semibold tracking-tight text-gray-900">
              <span v-if="billingCycle.value === 'monthly'">89.00 CHF</span>
              <span v-else>907.80 CHF</span>
            </span>
            <span class="text-sm font-semibold text-gray-600">
              / {{ billingCycle.value === 'monthly' ? $t('general.month') : $t('general.year') }}
            </span>
          </p>

          <p class="flex items-center mt-1 text-xs text-gray-600">
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.minDuration') }}
          </p>
          <!-- Discount note if annually -->
          <p
            v-if="billingCycle.value === 'annually'"
            class="text-sm text-green-600"
          >
            ({{ $t('publicArea.registerCompany.steps.websiteSubscription.fifteenPercentOff') }})
          </p>
          <!-- Button or CTA -->
          <btn
            @click="submitSubscription('professional')"
            class="mt-6 block rounded-md bg-gray-900
                   px-3 py-2 text-center text-sm font-semibold text-white
                   hover:bg-gray-800"
          >
            {{ $t('publicArea.registerCompany.steps.websiteSubscription.buyNowBtn') }}*
          </btn>

          <!-- Feature list -->
          <ul class="mt-8 space-y-3 text-sm text-gray-600">
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureCHDomain') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureOwnEmail') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureManagedHosting') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureUpdates') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureWordPressSite') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureMax15Pages') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureIndividualDesign') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSEOTexte') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureTechnicalSEO') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureCRM') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureReviews') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureReminder') }}
            </li>
            <li class="flex items-center">
              <font-awesome-icon icon="check" class="text-gray-700 mr-2"/>
              {{ $t('publicArea.registerCompany.steps.websiteSubscription.featureSupportTel') }}
            </li>
          </ul>
          <p class="mt-8 text-indigo-600 underline">
            <a href="https://wiv-reinigung.ch" target="_blank">
              Professional Beispiel-Website
            </a>
          </p>
        </div>
      </div>

      <!-- Navigation / Submit Buttons -->
      <div class="flex justify-center mt-10">
        <button
          class="btn btn-lg btn-primary ml-2"
          @click="next"
        >
          {{ $t('publicArea.registerCompany.steps.websiteSubscription.noThanksBtn') }}
        </button>
      </div>

      <p
        class="mx-auto mt-6 max-w-2xl text-pretty text-center text-sm font-normal text-gray-600 sm:text-lg/12 mb-12"
      >
        *{{ $t('publicArea.registerCompany.steps.websiteSubscription.agb') }}
      </p>

    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
  import { useI18n } from 'vue-i18n';
  import companyApi from '../../../connections/company.js';
  import { useMutation } from '@tanstack/vue-query';
  /* ------------------ Props & Emits ------------------ */
  const props = defineProps({
    company: {
      type: Object,
      default: () => ({}),
    },
  });
  const emit = defineEmits(['next-step']);

  const { t } = useI18n();

  const frequencies = [
    { value: 'monthly', label: t('general.monthly') },
    { value: 'annually', label: t('general.annually') },
  ];

  const billingCycle = ref(frequencies[0]);
  const selectedPlan = ref('standard');

  function choosePlan(plan) {
    selectedPlan.value = plan;
  }

  const submitSubscriptionMutation = useMutation({
    mutationFn: async () => {
      console.log('Submitting subscription for:', selectedPlan, billingCycle);

      if (!selectedPlan) throw new Error('No plan selected');
      if (!billingCycle) throw new Error('No billing cycle selected');

      const response = await companyApi.post(`/companies/${props.company.id}/subscription-checkout`, {
        progress: false,
        notification: false,
        plan: selectedPlan.value,
        billingCycle: billingCycle.value.value,
      }, {
        retry: 2,
      });

      return response.data.url;
    },
    onSuccess: (url) => {
      window.location.href = url;
    },
    onError: (error) => {
      console.error('Subscription Error:', error);
      // Optionally, display an error notification to the user
    },
  });

  // The refactored submitSubscription function
  function submitSubscription(plan) {
    selectedPlan.value = plan;
    if (!selectedPlan.value) return;

    submitSubscriptionMutation.mutate();
  }

  function next() {
    emit('next-step');
  }
</script>

<style scoped>
    /* You can add any extra styling or overrides here if desired. */
</style>
