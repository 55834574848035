<template>
  <div class="relative">
    <input
      id="email"
      v-model="props.company.email"
      :placeholder="$t('companyArea.components.contact.companyContact.email')"
      required
      type="email"
      class="border border-gray-400 w-full mt-2"
      @blur="checkEmail"
    />
    <font-awesome-icon
      v-if="emailIconState === 'valid'"
      :icon="['fal','check']"
      class="absolute right-3 top-5 text-lg text-green-500"
    />
    <font-awesome-icon
      v-else-if="emailIconState === 'invalid'"
      :icon="['fal','exclamation-circle']"
      class="absolute right-3 top-5 text-base text-red-500"
    />
    <!-- Show a backend error if it exists for "company.email" -->
    <p v-if="props.backendErrors['company.email']" class="text-red-500">
      {{ props.backendErrors['company.email'][0] }}
    </p>
  </div>

  <div class="relative">
    <input
      id="phone"
      v-model="props.company.phone"
      :placeholder="$t('companyArea.components.contact.companyContact.phone')"
      required
      class="border border-gray-400 w-full mt-2"
      type="text"
    />
    <font-awesome-icon
      v-if="phoneIconState === 'valid'"
      :icon="['fal','check']"
      class="absolute right-3 top-5 text-lg text-green-500"
    />
    <font-awesome-icon
      v-else-if="phoneIconState === 'invalid'"
      :icon="['fal','exclamation-circle']"
      class="absolute right-3 top-5 text-base text-red-500"
    />
    <!-- backend error for phone, if you store it as "company.phone" -->
    <p v-if="props.backendErrors['company.phone']" class="text-red-500">
      {{ props.backendErrors['company.phone'][0] }}
    </p>
  </div>
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import companyApi from '../../../connections/company';

  // Props
  const props = defineProps({
    company: {
      type: Object,
      required: true,
    },
    // Additional prop to pass down the parent's errors
    backendErrors: {
      type: Object,
      default: () => ({})
    }
  });

  // Tracking for the last known email
  const emailLastValue = ref(props.company.email);

  // For local (child) errors from email-checker
  const localErrors = ref({});

  /**
   * Use a mutation for email checking with @tanstack/vue-query
   */
  const emailCheckMutation = useMutation({
    mutationFn: (payload) => {
      return companyApi.post('companies/email-checker', payload, {
        progress: true,
        progressMessage: 'Wir überprüfen Ihre Email.'
      });
    },
    onError: (error) => {
      // Possibly store local error or do something with the 422
      if (error.response && error.response.data.errors) {
        localErrors.value = error.response.data.errors;
      }
    },
    onSuccess: () => {
      localErrors.value = {};
    },
  });

  /**
   * Check email only if changed
   */
  function checkEmail() {
    if (props.company.email && emailLastValue.value !== props.company.email) {
      localErrors.value = {};
      emailCheckMutation.mutate({ email: props.company.email });
    }
    emailLastValue.value = props.company.email;
  }

  // For icons: we can unify localErrors + parent's backendErrors
  const emailIconState = computed(() => {
    if (localErrors.value.email || props.backendErrors['company.email']) {
      return 'invalid';
    }
    if (props.company.email.length > 0) {
      return 'valid';
    }
    return null;
  });

  const phoneIconState = computed(() => {
    // Suppose no local check for phone
    if (props.backendErrors['company.phone']) {
      return 'invalid';
    }
    if (props.company.phone.length > 0) {
      return 'valid';
    }
    return null;
  });

</script>

<style scoped>
    /* style as needed */
</style>
