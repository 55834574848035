<template>
  <div v-if="!noWebsite" class="relative">
    <input
      id="website"
      v-model="websiteTemp"
      :class="[
        'border w-full mt-2',
        props.backendErrors['company.website'] ? 'border-red-500' : 'border-gray-400'
      ]"
      :placeholder="$t('companyArea.components.contact.companyWebsite.website')"
      required
      type="text"
      @blur="urlChecker"
    >

    <font-awesome-icon
      :icon="websiteIcon"
      class="absolute right-3 top-5 cursor-pointer"
      :class="websiteIconClass"
      @click="urlChecker"
    />
    <!-- server error for "company.website" if any -->
<!--    <p v-if="props.backendErrors['company.website']" class="text-red-500">-->
<!--      {{ props.backendErrors['company.website'][0] }}-->
<!--    </p>-->
  </div>

  <input
    v-model="noWebsite"
    type="checkbox"
    class="mt-2 mr-2 mb-3"
  >
  {{ $t('companyArea.components.contact.companyWebsite.noWebsiteYet') }}
</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import companyApi from '../../../connections/company';

  // Props
  const props = defineProps({
    company: {
      type: Object,
      required: true
    },
    register: {
      type: Boolean,
      default: false
    },
    backendErrors: {
      type: Object,
      default: () => ({})
    }
  });

  const noWebsite = ref(false);

  // We keep a temporary local input in case user changes
  const websiteTemp = ref('');

  // Use a mutation to check/clean URLs
  const urlCheckerMutation = useMutation({
    mutationFn: (payload) => {
      return companyApi.post('/url-checker', payload, {
        notification: true,
        progress: true,
        progressMessage: 'Wir überprüfen Ihre Webseite.',
      });
    },
    onSuccess: (response) => {
      props.company.website = response.data.content.url;
      websiteValidation.value = response.data.content.valid;
    },
    onError: () => {
      props.company.website = '';
      websiteValidation.value = false;
    }
  });

  // Local reactive value if the URL is valid
  const websiteValidation = ref(null);

  // Whenever `noWebsite` changes, update the company’s website
  watch(noWebsite, (val) => {
    if (val) {
      props.company.website = 'in-progress';
    } else if (websiteTemp.value) {
      // Potentially restore the typed URL
      props.company.website = websiteTemp.value;
    }
  });

  // On mount, if `company.website` is set, fill `websiteTemp`
  import { onMounted } from 'vue';
  onMounted(() => {
    if (props.company.website && props.company.website !== 'in-progress') {
      websiteTemp.value = props.company.website;
    }
  });

  function urlChecker() {
    if (!websiteTemp.value) return;
    // Call the mutation
    urlCheckerMutation.mutate({ websiteTemp: websiteTemp.value });
  }

  const websiteIcon = computed(() => {
    // If there's a backend error
    if (props.backendErrors['company.website']) {
      return ['fal', 'exclamation-circle'];
    }
    // If user has typed something & we have validated as true
    if (websiteValidation.value) {
      return ['fal', 'check'];
    }
    return ['fal', 'exclamation-circle'];
  });

  const websiteIconClass = computed(() => {
    if (props.backendErrors['company.website']) {
      return 'text-base text-red-500';
    }
    return websiteValidation.value ? 'text-lg text-green-500' : 'text-base';
  });
</script>

<style scoped></style>
