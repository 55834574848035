import * as packageJson from './../package.json';

console.log('VITE_VUE_APP_ENV: ', import.meta.env.VITE_VUE_APP_ENV);
console.log('app.version - package.json: ', packageJson.version);
import { version } from 'vue';

console.log('Vue Version: ', version);
console.log('MODE: ', import.meta.env.MODE);

import { createApp } from 'vue';
import App from './App.vue';

const app = createApp(App);

/**
 * Bugsnag has to be the first initialized plugin
 */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: import.meta.env.VITE_VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_VUE_APP_ENV,
  appVersion: packageJson.version,
  enabledReleaseStages: ['production'],
});
const bugsnagVue = Bugsnag.getPlugin('vue');
app.use(bugsnagVue);


//region localization
import { loadAndSetLocale, setupI18n } from './plugins/i18n';
const i18n = setupI18n();
app.use(i18n);
app.config.globalProperties.i18n = i18n;
export { i18n };
//endregion localization

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
app.component('VueDatePicker', VueDatePicker);

import VueHead from '@morr/vue3-head';
app.use(VueHead);

//region app Tenant
if (import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'vipany') {
  app.config.globalProperties.companyLogo = 'logo-vipany-bw.jpg';
  app.config.globalProperties.appName = 'Vipany';
} else if (import.meta.env.VITE_VUE_APP_CURRENT_COMPANY === 'topofferten') {
  app.config.globalProperties.companyLogo = 'logo-topofferten-bw-ch.jpg';
  app.config.globalProperties.appName = 'top-offerten';
}
//endregion app Tenant

import store from './components/store/mainStore';
app.use(store);

import router from './router';
import './plugins/routeGuard';
app.use(router);

import { VueCookieNext } from 'vue-cookie-next';

app.use(VueCookieNext);
VueCookieNext.config({
  expire: '7d',
  path: '/',
  domain: '',
  secure: '',
  sameSite: '',
});

//region FontAwesome
// Info see: https://fontawesome.com/docs/web/use-with/vue/add-icons
// noinspection ES6UnusedImports
import './plugins/fontawesome'; // To use an Icon, it has to be added in this File here.
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

app.component('FontAwesomeIcon', FontAwesomeIcon);
//endregion FontAwesome

// region dayjs
import dayjsHelper from './plugins/dayjsHelper';
app.config.globalProperties.dayjsHelper = dayjsHelper;
// endregion dayjs

import './styles/index.css';
/**
 * Logs User in from cookie, if present
 */
if (store.getters.isGuest) {
  let user = VueCookieNext.getCookie('user');
  if (user && user !== 'null' && (user.locale === null || user.locale === 'fr' || user.locale === 'en' || user.locale === 'de-ch')) {
    store.commit('resetState');
    user = null;
  }
  if (user && user !== 'null') {
    console.log('Set User from cookie', user);
    store.commit('setUser', user, false);
    store.commit('setCompanies', (user.employees !== undefined ? user.employees : []));
    Bugsnag.addMetadata('user-cookie', { id: user.id, email: user.email });
  }
  var cookies = VueCookieNext.getCookie('cookies');
  if (!cookies || cookies === 'false') {
    store.commit('setCookiesAccepted', false);
  }
}

if (store.getters.isGuest) {
  const host = location.host;
  const locale = host.includes('top-offerten') ? 'de-CH' :
    host.includes('top-quotes') ? 'en-US' :
      host.includes('top-offres') ? 'fr-CH' :
        'de-CH';
  console.log('mainJs loadAndSetLocale because of isGuest: ' + locale);
  loadAndSetLocale(locale);
}

import VueAxios from 'vue-axios';
import authApi from './components/connections/auth';
import leadFormApi from './components/connections/leadForm';

app.use(VueAxios, authApi);
app.use(VueAxios, leadFormApi);

import { VueQueryPlugin } from '@tanstack/vue-query';
app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true
})

/**
 * global Function used in all focusActivities
 */
app.mixin({
  methods: {},
});

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const toastOptions = {
  useDefaultCss: false,
  // You can set your default toastOptions here
};
app.use(Toast, toastOptions);

import { initGtm } from './plugins/gtm.js';

initGtm();

// Wait for i18n to be ready before mounting the app
async function waitForI18nReady(i18n) {
  while (!i18n.ready) {
    console.log('Waiting for i18n to be ready...')
    await new Promise(resolve => setTimeout(resolve, 50));
  }
}

(async () => {
  // Wait for i18n to be ready before mounting the app
  await waitForI18nReady(i18n);
  console.log('i18n is ready!');

  app.mount('#app');
})();
