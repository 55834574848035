<template>
  <!-- REGISTER MODE: Show radius input & Save button -->
  <div v-if="register && currentLocation" class="w-96 max-w-full flex flex-col justify-center">
    <input
      v-model="currentLocation.radius"
      class="w-full text-center border border-gray-400 mt-5"
      type="number"
    />
    <p class="text-center mt-3">
      {{ $t('companyArea.components.map.radiusText1') }} <br/>
      {{ $t('companyArea.components.map.radiusText2') }}
    </p>

    <div v-if="currentRadius > 0 && unsaved" class="flex justify-center mt-3 mb-3">
      <button
        :class="{ 'pointer-events-none': !readyForSave }"
        class="btn btn-lg btn-primary"
        @click="storeArea('all')"
      >
        {{ $t('general.next') }}
      </button>
    </div>
  </div>

  <!-- SAVE BUTTONS (EDIT MODE) -->
  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button
      :class="{ 'pointer-events-none': !readyForSave }"
      class="btn btn-lg btn-primary"
      @click="submitChanges"
    >
      {{ $t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>
  <div v-if="unsaved && !register" class="text-red-700 my-2">
    <span class="font-bold">{{ $t('general.attention') }}</span>
    {{ $t('companyArea.components.map.unsavedChanges') }}
  </div>

  <!-- MAP CONTAINER & SPINNER -->
  <div id="map" class="relative w-full" style="height: 500px;">
    <!-- Single Spinner (covers data load, radius recalc, or query loading) -->
    <spinner
      v-if="(!loaded || reload) || postcodesGeoJsonLoading"
      ref="reload"
      class="opacity-50"
      style="z-index: 10000;"
    />
  </div>

  <!-- PRECISION SLIDER -->
  <div class="grid place-content-end">
    <div class="basis-1">
      <div class="w-full">
        <div class="flex justify-between">
          <div class="text-xs mt-1">{{ $t('companyArea.components.map.unprecise') }}</div>
          <div class="text-xs mt-1">{{ $t('companyArea.components.map.precise') }}</div>
        </div>
      </div>
      <input
        v-model="precision"
        class="rounded-lg appearance-none bg-gray-200 h-1 w-full mt-0"
        data-cy="input-range-local"
        max="3"
        min="0"
        step="1"
        type="range"
      />
    </div>
  </div>

  <!-- (DUPLICATE) SAVE BUTTONS (EDIT MODE) -->
  <div v-if="unsaved && !register" class="flex justify-center mt-3">
    <button
      :class="{ 'pointer-events-none': !readyForSave }"
      class="btn btn-lg btn-primary"
      @click="submitChanges"
    >
      {{ $t('companyArea.components.map.saveChangesForClassification', { name: classification.name }) }}
    </button>
  </div>

  <!-- ZIP SELECTION BLOCK -->
  <p v-if="!register && calc" class="text-xs text-gray-500 mt-4">
    {{ $t('companyArea.components.map.postcodesInfo') }}
  </p>
  <div v-if="!register && calc" class="mt-4 border rounded-md p-2">
    <!-- For brevity, each row of zipBtn is similar. -->
    <div class="flex">
      <div
        v-for="zipBtn in [10,11,12,13,14,15,16,17,18,19]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [20,21,22,23,24,25,26,27,28,29]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [30,31,32,33,34,35,36,37,38,39]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [40,41,42,43,44,45,46,47,48,49]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [50,51,52,53,54,55,56,57]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [60,61,62,63,64,65,66,67,68,69]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [70,71,72,73,74,75,76,77]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [80,81,82,83,84,85,86,87,88,89]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
    <div class="flex">
      <div
        v-for="zipBtn in [90,91,92,93,94,95,96]"
        :key="zipBtn"
        :class="{ 'bg-green-200': activeZip.includes(zipBtn) }"
        class="p-2 cursor-pointer"
        @click="recalculateArea(zipBtn)"
      >
        {{ zipBtn }}
      </div>
    </div>
  </div>

  <!-- CONFIRMATION MODAL -->
  <confirmation-modal v-if="modal" style="z-index: 1000;" @close="modal = false">
    <div class="text-center text-gray-900 text-lg font-bold">
      {{ $t('companyArea.components.map.changeForAllClassificationsQuestion', { name: classification.name }) }}
    </div>
    <div class="flex mt-4 justify-center">
      <div
        class="px-3 py-2 border rounded-md mr-3 mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
        @click="storeArea('only')"
      >
        {{ $t('companyArea.components.map.onlyClassification', { name: classification.name }) }}
      </div>
      <div
        class="px-3 py-2 border rounded-md mb-3 bg-gray-100 hover:bg-gray-300 cursor-pointer"
        @click="storeArea('all')"
      >
        {{ $t('companyArea.components.map.forAll') }}
      </div>
    </div>
    <div v-if="classifications.some(obj => obj.radius !== null && obj.id !== classification.id)">
      <div class="flex mt-2 justify-center">
        <div
          class="px-3 py-2 border rounded-md mb-2 bg-gray-100 hover:bg-gray-300 cursor-pointer"
          @click="storeArea('standard')"
        >
          {{ $t('companyArea.components.map.forAllExcept') }}
        </div>
      </div>
      <div class="flex justify-center">
        <div
          v-for="specialClassification in classifications.filter(obj => obj.radius !== null && obj.id !== classification.id)"
          :key="specialClassification.id"
          class="text-xs px-2 py-1 border rounded-md mb-2 bg-gray-300"
        >
          {{ specialClassification.name }}
        </div>
      </div>
    </div>
  </confirmation-modal>

  <!-- FINAL LOADING SPINNER WHEN STORING TO SERVER -->
  <spinner v-if="waitForServerResponse" style="z-index: 10000;"/>
</template>

<script setup>
  import { ref, reactive, computed, watch, onMounted, nextTick } from 'vue';
  import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import 'leaflet/dist/leaflet.js';

  import companyApi from '../../connections/company';
  import locationApi from '../../connections/location';
  import Spinner from '../../snippets/Spinner.vue';
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import store from '../../store/mainStore';
  import markerIcon from 'leaflet/dist/images/marker-icon.png';
  import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
  import markerShadow from 'leaflet/dist/images/marker-shadow.png';
  import notification from '../../connections/notification';

  // Props & Emits
  const props = defineProps({
    register: { type: Boolean, default: false },
    locations: { type: Array, default: () => [] },
    classification: { type: Object, default: () => ({}) },
    classifications: { type: Array, default: () => [] },
    company: { type: Object, default: () => ({}) },
    radiusFromDirectFilter: { type: Number, default: null },
    calc: { type: Boolean, default: true },
  });
  const emit = defineEmits(['saved']);

  // Refs & Reactive Data
  const map = ref(null);
  const layer = reactive({});
  const postcodes = ref([]);
  const geoJson = ref([]);

  const zipPostcodes = ref([]);
  const radiusPostcodes = ref([]);
  const companyPostcodes = reactive({});

  const currentLocation = ref(null);
  const loaded = ref(false);
  const reload = ref(false); // For showing spinner during circle recalc
  const waitForServerResponse = ref(false);
  const readyForSave = ref(true);
  const unsaved = ref(false);
  const modal = ref(false);
  const precision = ref(null); // 0..3 - Set onMounted
  const formdata = reactive({ radius: [] });
  const popup = ref(null);
  const activeZip = ref([]);
  const locationsCloned = ref([]);

  // For the popup "Show" / "Ok" label
  const saveBtnText = computed(() => props.$t?.('general.show') || 'Show');

  // Initialize
  onMounted(() => {
    unsaved.value = props.register;
    locationsCloned.value = JSON.parse(JSON.stringify(props.locations));
    currentLocation.value = props.locations.find(item => item.headquarter === 1);
    precision.value = computeInitialPrecision(props.classification, props.locations);
    initAreaSettings();
  });

  // Vue Query
  const queryClient = useQueryClient();

  // 1) GET [postcodes, geoJson]
  const {
    data: postcodesGeoJsonData,
    isLoading: postcodesGeoJsonLoading,
    refetch: refetchPostcodesGeoJson,
  } = useQuery({
    queryKey: computed(() => ['postcodes-and-geojson', precision.value]),
    queryFn: async () => {
      const response = await locationApi.get(`postcodes-and-geojson?precision=${precision.value}`);
      return response.data; // e.g. [postcodesArr, geoJsonArr]
    },
    enabled: computed(() => precision.value !== null),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    gcTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    staleTime: 60 * 59 * 1000,
    keepPreviousData: true,
  });

  // 2) POST storeArea
  const { mutate: mutateStoreArea } = useMutation({
    mutationFn: (payload) =>
      companyApi.post(`companies/${props.company.id}/postcodes/sync`, payload, {
        'axios-retry': { retries: 0 },
      }),
    onSuccess: (response) => {
      if (response.data.notificationType === 'success') {
        store.commit('company/setInitialCompanyData', response.data.content.company);
        unsaved.value = false;
        emit('saved');
      } else {
        notifyValidationError(response.data.message);
      }
    },
    onError: (error) => {
      const msg = error?.response?.data?.message || 'Error in storeArea';
      notifyValidationError(msg);
    },
    onSettled: () => {
      modal.value = false;
      waitForServerResponse.value = false;
    },
  });

  // WATCH: postcodesGeoJsonData => init map once loaded
  watch(
    () => postcodesGeoJsonData?.value,
    (val) => {
      if (!val) return;
      const [postcodesArr, geoJsonArr] = val;
      postcodes.value = postcodesArr || [];
      geoJson.value = geoJsonArr || [];

      if (!loaded.value && postcodes.value.length && geoJson.value.length) {
        destroyMapIfExists();
        initMap().then(() => {
          locationsCloned.value.forEach((loc) => {
            drawCircle(loc);
            drawMarker(loc);
          });
          loaded.value = true;
        });
      }
    },
  );

  // WATCH: classification => maybe re-check precision
  watch(
    () => props.classification,
    (newVal, oldVal) => {
      if (!oldVal || newVal.id !== oldVal.id) {
        const oldPrecision = precision.value;
        const newPrecision = computeInitialPrecision(props.classification, props.locations);

        unsaved.value = false;
        if (popup.value?._source) popup.value._source.closePopup();

        initAreaSettings();
        if (newPrecision !== oldPrecision) {
          precision.value = newPrecision;
        } else {
          handlePrecisionChange();
        }
      }
    },
  );

  // WATCH: precision => refetch data & re-init map
  watch(
    () => precision.value,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        handlePrecisionChange();
      }
    },
  );

  // WATCH: radiusFromDirectFilter => re-draw circles
  watch(
    () => props.radiusFromDirectFilter,
    () => {
      locationsCloned.value.forEach((loc) => {
        drawCircle(loc);
        drawMarker(loc);
      });
    },
  );

  // Current radius (HQ) from formdata
  const currentRadius = computed(() => {
    const found = formdata.radius.find((item) => item.id === currentLocation.value?.id);
    return found ? found.radius : null;
  });

  // WATCH: changes to current HQ radius => recalc if in register mode
  watch(currentRadius, () => {
    if (loaded.value) {
      drawCircle(currentLocation.value);
      if (props.register) {
        reload.value = true;
        readyForSave.value = false;
        recalculateArea(null).then(() => {
          reload.value = false;
          readyForSave.value = true;
        });
      }
    }
  });

  /* ----------------------------------
     Methods
  ---------------------------------- */

  /** (Re-)loads data from the server when precision changes */
  async function handlePrecisionChange() {
    loaded.value = false;
    destroyMapIfExists();
    zipPostcodes.value = [];
    radiusPostcodes.value = [];
    for (const key in companyPostcodes) {
      delete companyPostcodes[key];
    }
    await refetchPostcodesGeoJson();
  }

  /** Compute initial precision based on classification or location data */
  function computeInitialPrecision(classif, locs) {
    let radiusSum = 0;
    let zipSum = 0;

    if (!classif || !classif.separate_area) {
      radiusSum = locs.reduce((acc, val) => acc + (val?.radius || 0), 0);
      zipSum = locs.reduce((acc, val) => acc + ((val.zip?.length ?? 0) * 3), 0);
    } else {
      if (classif.radius) {
        radiusSum = classif.radius.reduce((acc, val) => acc + (val?.radius || 0), 0);
      }
      if (classif.zip) {
        zipSum = classif.zip.length * 3;
      }
    }
    const total = parseInt(radiusSum) + parseInt(zipSum);
    if (total > 100) return 0;
    if (total > 60 || total === 0) return 1;
    if (total > 40) return 2;
    return 3;
  }

  /** Initialize area/radius + zip settings from classification or fallback */
  function initAreaSettings() {
    let radArr = [];
    let zipArr = [];

    if (props.classification.radius) {
      radArr = JSON.parse(JSON.stringify(props.classification.radius));
      zipArr = JSON.parse(JSON.stringify(props.classification.zip));
    } else {
      // fallback to location data
      locationsCloned.value.forEach((obj) => {
        radArr.push({ id: obj.id, radius: obj.radius });
      });
      const hq = locationsCloned.value.find((obj) => obj.headquarter === 1);
      zipArr = hq?.zip || [];
    }
    formdata.radius = radArr;
    activeZip.value = zipArr ?? [];
  }

  /** If we have an existing map, remove it to avoid double-initialize */
  function destroyMapIfExists() {
    if (map.value) {
      map.value.remove();
      map.value = null;
    }
    const mapContainer = document.getElementById('map');
    if (mapContainer && mapContainer._leaflet_id) {
      mapContainer._leaflet_id = null;
    }
  }

  /** Initialize the Leaflet map, tile layer, etc. */
  async function initMap() {
    const mapContainer = document.getElementById('map');
    if (map.value) {
      map.value.remove();
      map.value = null;
    }
    if (mapContainer && mapContainer._leaflet_id) {
      mapContainer._leaflet_id = null;
    }

    map.value = L.map(mapContainer).setView([46.8, 8.23], 8);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
    }).addTo(map.value);

    // Prepare zip-based postcodes
    zipPostcodes.value = postcodes.value.filter((pc) =>
      activeZip.value.some((z) => pc.zip.startsWith(z)),
    );

    // do an initial recalc
    await recalculateArea(null, true);
    loaded.value = true;
    readyForSave.value = true;

    // Bring circles to front if any
    locationsCloned.value.forEach((obj) => {
      if (obj.mapCircle) obj.mapCircle.bringToFront();
    });
  }

  /** Draw a Leaflet marker at the location’s postcode coords */
  function drawMarker(location) {
    if (!postcodes.value.length) return;
    const p = postcodes.value.find((obj) => obj.id === location.postcode_id);
    if (!p) return;

    const markerIconDefault = L.icon({
      iconUrl: markerIcon,
      iconRetinaUrl: markerIcon2x,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowUrl: markerShadow,
      shadowSize: [41, 41],
      shadowAnchor: [12, 41],
    });

    const marker = L.marker([p.latitude, p.longitude], { icon: markerIconDefault }).addTo(map.value);

    // Only show a popup if not register & we have calc mode
    if (!props.register && props.calc) {
      const width = 90 + saveBtnText.value.length * 7;
      popup.value = L.popup().setContent(`
      <div style="width: ${width}px; max-width:200px">
        <div class="mt-2 flex rounded-md shadow-sm">
          <div class="relative flex flex-grow items-stretch focus-within:z-10">
            <input type="text" name="radius" id="inputField${location.id}"
                   class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900
                          ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                          focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <span class="text-gray-500 sm:text-sm">Km</span>
            </div>
          </div>
          <button type="button" id="btn${location.id}"
                  class="relative -ml-px inline-flex items-center gap-x-1.5
                         rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900
                         ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            ${saveBtnText.value}
          </button>
        </div>
      </div>
    `);

      marker.bindPopup(popup.value, {
        minWidth: 100,
        maxWidth: 300,
        closeButton: true,
      });

      marker.on('popupopen', () => {
        currentLocation.value = location;
        document.addEventListener('keydown', closePopupWithEnter);
        nextTick(() => {
          const inputField = document.getElementById(`inputField${location.id}`);
          const saveButton = document.getElementById(`btn${location.id}`);
          // Init input with current radius
          inputField.value = getCurrentRadius(location) ?? 0;

          // watchers
          inputField.addEventListener('input', (e) => {
            const newVal = Number(e.target.value);
            const found = formdata.radius.find((r) => r.id === location.id);
            if (found) found.radius = newVal;
          });
          saveButton.addEventListener('click', () => {
            popup.value._source.closePopup();
          });
        });
      });

      marker.on('popupclose', () => {
        document.removeEventListener('keydown', closePopupWithEnter);
        const oldRadius = location.radius;
        const newRadius = getCurrentRadius(location);
        // If user changed the radius, recalc
        if (oldRadius !== newRadius) {
          reload.value = true;
          readyForSave.value = false;
          recalculateArea(null).then(() => {
            reload.value = false;
            readyForSave.value = true;
          });
        }
      });

      function closePopupWithEnter(e) {
        if (e.key === 'Enter') {
          popup.value._source.closePopup();
        }
      }
    }
  }

  /** Draw a Leaflet circle for the location’s radius */
  function drawCircle(location) {
    if (location.mapCircle) {
      location.mapCircle.removeFrom(map.value);
      location.mapCircle = null;
    }
    // only if calc mode or directFilter set
    if (!props.calc && !props.radiusFromDirectFilter) return;

    const p = postcodes.value.find((obj) => obj.id === location.postcode_id);
    if (!p) return;

    const circleRadius =
      (props.radiusFromDirectFilter ?? getCurrentRadius(location) ?? 10) * 1000;

    if (Number.isNaN(circleRadius)) {
      notification.error($t('companyArea.components.map.radiusErrorNotification'));
      return;
    }

    location.mapCircle = L.circle([p.latitude, p.longitude], {
      radius: circleRadius,
      color: 'grey',
      weight: 1,
      fillColor: 'grey',
      fillOpacity: props.calc ? 0.2 : 0.3,
    }).addTo(map.value);
  }

  function getCurrentRadius(loc) {
    const found = formdata.radius.find((r) => r.id === loc.id);
    return found?.radius ?? loc.radius;
  }

  /** Recompute area => zip + radius postcodes => choropleth layering */
  async function recalculateArea(zip, init = false) {
    reload.value = true;
    // Short pause so spinner can appear
    await new Promise((resolve) => setTimeout(resolve, 5));

    if (!init) {
      unsaved.value = true;
    }

    // If user toggled a zip button
    if (zip) {
      readyForSave.value = false;
      if (activeZip.value.includes(zip)) {
        activeZip.value = activeZip.value.filter((z) => z !== zip);
      } else {
        activeZip.value.push(zip);
      }
      zipPostcodes.value = postcodes.value.filter((pc) =>
        activeZip.value.some((z) => pc.zip.startsWith(z)),
      );
    } else {
      // Recalc radius-based postcodes
      radiusPostcodes.value = [];
      const earthRadiusKm = 6371;
      for (const loc of locationsCloned.value) {
        const locRadius = getCurrentRadius(loc) ?? 0;
        if (Number.isNaN(locRadius)) {
          notification.error($t('companyArea.components.map.radiusErrorNotification'));
          reload.value = false;
          return true;
        }
        const basePc = postcodes.value.find((pc) => pc.id === loc.postcode_id);
        if (!basePc) continue;

        for (const pc of postcodes.value) {
          const dLat = deg2rad(pc.latitude - basePc.latitude);
          const dLon = deg2rad(pc.longitude - basePc.longitude);
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(basePc.latitude)) *
            Math.cos(deg2rad(pc.latitude)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          const distance = earthRadiusKm * c;
          if (distance <= locRadius) {
            radiusPostcodes.value.push({ ...pc, location_id: loc.id, distance });
          }
        }
      }
    }

    const combined = [...new Set([...radiusPostcodes.value, ...zipPostcodes.value])];
    const result = await samplePostcodes(combined);

    reload.value = false;
    if (result && zip) {
      readyForSave.value = true;
    }
    return true;
  }

  /** Merge postcodes into companyPostcodes state & update map layers */
  async function samplePostcodes(all) {
    const bucket = {};
    for (const p of all) {
      bucket[p.state_code] = bucket[p.state_code] || [];
      if (!bucket[p.state_code].some((xx) => xx.id === p.id)) {
        bucket[p.state_code].push(p);
      }
    }

    // remove layers no longer needed
    for (const key in companyPostcodes) {
      if (!bucket[key] || bucket[key].length === 0) {
        layer[key]?.removeFrom(map.value);
        delete companyPostcodes[key];
      }
    }

    let i = 0;
    for (const area in bucket) {
      if (!companyPostcodes[area] || companyPostcodes[area].length !== bucket[area].length) {
        companyPostcodes[area] = bucket[area];
        await calcChoroplethData(area, companyPostcodes[area]);
      }
      i++;
    }
    return i === Object.keys(bucket).length;
  }

  /** Convert list of postcodes => GeoJSON => draw the shading */
  async function calcChoroplethData(area, areaPostcodes) {
    const geoJsonData = [];
    for (const p of areaPostcodes) {
      const matched = geoJson.value.filter((g) => g.zip === p.zip);
      matched.forEach((m) => {
        if (!geoJsonData.includes(m)) {
          geoJsonData.push(m);
        }
      });
    }
    const data = [{ type: 'FeatureCollection', features: [] }];
    for (const g of geoJsonData) {
      data[0].features.push({
        type: 'Feature',
        properties: { id: g.id, name: g.name, zip: g.zip, value: 2 },
        geometry: {
          type: 'Polygon',
          coordinates: JSON.parse(g.geojson),
        },
      });
    }
    await updateChoroplethData(area, data);
  }

  async function updateChoroplethData(area, data) {
    if (layer[area]) {
      layer[area].removeFrom(map.value);
    }
    layer[area] = L.geoJSON(data, {
      style: (feature) => {
        const value = feature.properties.value;
        return {
          fillColor: getColor(value),
          weight: 1,
          opacity: 0.4,
          color: 'white',
          fillOpacity: 0.5,
        };
      },
      onEachFeature: (feature, lyr) => {
        lyr.bindPopup(`Ort: ${feature.properties.name}<br>PLZ: ${feature.properties.zip}`);
      },
    }).addTo(map.value);
    return true;
  }

  /** Basic color scale for the choropleth */
  function getColor(value) {
    return value > 2
      ? '#8b233b'
      : value > 1
        ? '#8d3882'
        : value > 0.5
          ? '#bae4b3'
          : '#edf8e9';
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Save/Store area changes
  function submitChanges() {
    if (props.classifications.length > 1) {
      modal.value = true;
    } else {
      storeArea('all');
    }
  }

  function storeArea(forAllClassifications) {
    waitForServerResponse.value = true;
    const companyPostcodesArr = Object.keys(companyPostcodes).map((key) => [companyPostcodes[key]]);
    const payload = {
      ...formdata,
      companyPostcodes: companyPostcodesArr,
      activeZip: activeZip.value,
      classification: props.classification,
      forAllClassifications,
    };
    mutateStoreArea(payload);
  }

  function notifyValidationError(msg) {
    try {
      notification.error(msg.replace(/Radius\.0\.radius/g, 'Radius'));
    } catch {
      notification.error('Es ist ein Fehler aufgetreten');
    }
  }
</script>

<style scoped>
    /* Keep or add any relevant styles here */
</style>
