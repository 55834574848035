<template>
  <div class="mx-auto px-4 sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl">
    <!-- First Row: Name | Surname -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Name -->
      <div class="relative">
        <label for="name" class="block text-gray-700">
          {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.firstName') }}
        </label>
        <input
          id="name"
          v-model="form.name"
          required
          type="text"
          :class="['w-full', 'mt-1', errors['name'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
        />
        <font-awesome-icon
          :icon="nameIcon"
          class="absolute right-3 top-9"
          :class="nameIconClass"
        />
        <p v-if="errors['name']" class="text-red-500">
          {{ errors['name'][0] }}
        </p>
      </div>

      <!-- Surname -->
      <div class="relative">
        <label for="surname" class="block text-gray-700">
          {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.lastName') }}
        </label>
        <input
          id="surname"
          v-model="form.surname"
          required
          type="text"
          :class="['w-full', 'mt-1', errors['surname'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
        />
        <font-awesome-icon
          :icon="surnameIcon"
          class="absolute right-3 top-9"
          :class="surnameIconClass"
        />
        <p v-if="errors['surname']" class="text-red-500">
          {{ errors['surname'][0] }}
        </p>
      </div>
    </div>

    <!-- Official Company Name -->
    <div class="relative mt-4">
      <label for="officialName" class="block text-gray-700">
        {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.officialCompanyName') }}
      </label>
      <input
        id="officialName"
        v-model="form.company.name"
        required
        type="text"
        :class="['w-full', 'mt-1', errors['company.name'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
      />
      <font-awesome-icon
        :icon="surnameIcon"
        class="absolute right-3 top-9"
        :class="surnameIconClass"
      />
      <p v-if="errors['company.name']" class="text-red-500">
        {{ errors['company.name'][0] }}
      </p>
    </div>
    <p class="text-gray-500 text-xs mb-2">
      {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.officialCompanyNameInfo') }}
    </p>

    <!-- Company Label -->
    <div class="relative mt-4">
      <label for="companyLabel" class="block text-gray-700">
        {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.companyLabel') }}
      </label>
      <input
        id="companyLabel"
        v-model="form.company.label"
        required
        type="text"
        :class="['w-full', 'mt-1', errors['company.label'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
      />
      <font-awesome-icon
        v-if="form.company.label.length > 0"
        :icon="surnameIcon"
        class="absolute right-3 top-9"
        :class="surnameIconClass"
      />
      <p v-if="errors['company.label']" class="text-red-500">
        {{ errors['company.label'][0] }}
      </p>
    </div>
    <p class="text-gray-500 text-xs mb-2">
      {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.companyLabelInfo') }}
    </p>

    <!-- Address Section (Merged CompanyCity) -->
    <div class="grid grid-cols-1 sm:grid-cols-5 gap-4 mt-4">
    <!-- Address -->
      <div class="sm:col-span-5">
        <div class="relative space-y-0.5">
          <label for="address" class="block text-gray-700">
            {{ $t('companyArea.components.contact.companyCity.street') }}
          </label>
          <input
            id="address"
            v-model="form.company.locations[0].address"
            :class="['w-full', 'mt-1', errors['company.locations.0.address'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
            required
            type="text"
          />
          <font-awesome-icon
            :icon="addressIcon"
            class="absolute right-3 top-7"
            :class="addressIconClass"
          />
          <p v-if="errors['company.locations.0.address']" class="text-red-500 mt-1">
            {{ errors['company.locations.0.address'][0] }}
          </p>
        </div>
      </div>

      <!-- Postcode -->
      <div class="sm:col-span-1">
        <div class="relative space-y-0.5">
          <label for="postcode" class="block text-gray-700">
            {{ $t('companyArea.components.contact.companyCity.zip') }}
          </label>
          <input
            id="postcode"
            v-model="form.company.locations[0].postcode"
            :class="['w-full', 'mt-1', errors['company.locations.0.postcode'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
            required
            type="text"
            @input="getCityOptions"
          />
          <font-awesome-icon
            :icon="postcodeIcon"
            class="absolute right-3 top-7"
            :class="postcodeIconClass"
          />
          <p v-if="errors['company.locations.0.postcode']" class="text-red-500 mt-1">
            {{ errors['company.locations.0.postcode'][0] }}
          </p>
        </div>
      </div>

      <!-- City Dropdown -->
      <div class="sm:col-span-4">
        <div class="relative space-y-0.5">
          <label for="city" class="block text-gray-700">
            {{ $t('companyArea.components.contact.companyCity.city') }}
          </label>
          <select
            id="city"
            v-model="form.company.locations[0].city"
            :class="['w-full', 'mt-1', errors['company.locations.o.city'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
            required
          >
            <option
              v-if="cityOptions.length === 0"
              :value="null"
              class="text-gray-600"
            >
              {{ $t('companyArea.components.contact.companyCity.selectCity') }}
            </option>
            <option
              v-for="option in cityOptions"
              :key="'cityOption' + option.id"
              :value="option.id"
            >
              {{ option.city }}
            </option>
          </select>
          <p v-if="errors['company.locations.0.city']" class="text-red-500 mt-1">
            {{ errors['company.locations.0.city'][0] }}
          </p>
        </div>
      </div>
    </div>

    <!-- Third Row: Email | Phone -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      <!-- Email Field with Validation -->
      <div class="relative">
        <label for="contactEmail" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyContact.email') }}
        </label>
        <input
          id="contactEmail"
          v-model="form.company.email"
          :placeholder="$t('companyArea.components.contact.companyContact.email')"
          required
          type="email"
          :class="['w-full', 'mt-1', errors['company.email'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
          @blur="checkEmail"
        />
        <font-awesome-icon
          :icon="emailIcon"
          class="absolute right-3 top-9"
          :class="emailIconClass"
          @click="checkEmail"
        />
        <p v-if="errors['company.email']" class="text-red-500">
          {{ errors['company.email'][0] }}
        </p>
      </div>

      <!-- Phone Field with Validation -->
      <div class="relative">
        <label for="contactPhone" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyContact.phone') }}
        </label>
        <input
          id="contactPhone"
          v-model="form.company.phone"
          :placeholder="$t('companyArea.components.contact.companyContact.phone')"
          required
          type="text"
          :class="['w-full', 'mt-1', errors['company.phone'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
        />
        <font-awesome-icon
          v-if="phoneIconState === 'valid'"
          :icon="['fal','check']"
          class="absolute right-3 top-9 text-lg text-green-500"
        />
        <font-awesome-icon
          v-else-if="phoneIconState === 'invalid'"
          :icon="['fal','exclamation-circle']"
          class="absolute right-3 top-9 text-base text-red-500"
        />
        <p v-if="errors['company.phone']" class="text-red-500">
          {{ errors['company.phone'][0] }}
        </p>
      </div>
    </div>

    <!-- Fourth Row: Website -->
    <div v-if="!noWebsite" class="relative mt-4">
      <label for="website" class="block text-gray-700">
        {{ $t('companyArea.components.contact.companyWebsite.website') }}
      </label>
      <input
        id="website"
        v-model="websiteTemp"
        :class="[ 'border w-full mt-1', errors['company.website'] ? 'border-red-500' : 'border-gray-400' ]"
        required
        type="text"
        @blur="urlChecker"
      />
      <font-awesome-icon
        :icon="websiteIcon"
        class="absolute right-3 top-9"
        :class="websiteIconClass"
        @click="urlChecker"
      />
      <p v-if="errors['company.website']" class="text-red-500">
        {{ errors['company.website'][0] }}
      </p>
    </div>

    <div class="flex items-center mt-2">
      <input
        v-model="noWebsite"
        type="checkbox"
        class="mt-2 mr-2 mb-3"
      >
      <label for="noWebsite" class="text-gray-700">
        {{ $t('companyArea.components.contact.companyWebsite.noWebsiteYet') }}
      </label>
    </div>

    <!-- Password -->
    <div class="mt-4">
      <label for="password" class="block text-gray-700">
        {{ $t('publicArea.registerCompany.steps.registerUserWithCompany.choosePassword') }}
      </label>
      <input
        id="password"
        v-model="form.password"
        required
        type="password"
        :class="['w-full', 'mt-1', errors['password'] ? 'border-red-500 border-2' : 'border-gray-400 border' ]"
      />
      <p v-if="errors['password']" class="text-red-500">
        {{ errors['password'][0] }}
      </p>
    </div>

    <p v-if="errors['uid']" class="text-red-500 my-4 text-lg font-bold">
      {{ errors['uid'][0] }}
    </p>

    <!-- Submit -->
    <div class="flex justify-center mt-6">
      <button
        class="btn btn-lg btn-primary"
        type="button"
        @click="submitForm"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? $t('general.loading') : $t('general.next') }}
      </button>
    </div>
  </div>
</template>

<script setup>
  /* ------------------ Imports ------------------ */
  import { ref, computed, watch, onMounted } from 'vue';
  import { useMutation } from '@tanstack/vue-query';
  import { useStorage } from '@vueuse/core';
  import { useRoute } from 'vue-router';

  import store from '../../../store/mainStore';
  import notification from '../../../connections/notification';
  import authApi from '../../../connections/auth';
  import companyApi from '../../../connections/company';

  /* ------------------ Props & Emits ------------------ */
  const props = defineProps({
    company: {
      type: Object,
      default: () => ({}),
    },
    uidData: {
      type: Object,
      default: () => ({}),
    },
  });
  const emit = defineEmits(['next-step']);

  /* ------------------ Local Form State ------------------ */
  const form = useStorage('register-company-form', {
    uid: '',
    uidErgaenzung: '',
    name: '',
    surname: '',
    password: '',
    locale: 'de-CH', // Set later from i18n if desired
    company: {
      name: '',
      label: '',
      email: '',
      website: '',
      phone: '',
      locations: [
        {
          postcode_id: null,
          address: '',
          postcode: '',
          city: '',
          headquarter: 1,
          state_code: '',
        },
      ],
    },
  });

  /* ------------------ Track Loading & Errors ------------------ */
  const isSubmitting = ref(false);
  const errors = ref({
    'company.name': null,
    'company.label': null,
    'company.website': null,
    'company.phone': null,
    'company.locations.0.address': null,
    'company.locations.0.postcode': null,
    'company.locations.0.city': null,
    'company.email': null,
    'name': null,
    'surname': null,
    'password': null,
  });

  /* ------------------ Lifecycle ------------------ */
  const route = useRoute();
  watch(() => props.uidData, (newUidData) => {
      console.log('New uidData:', newUidData);
      if (newUidData && Object.keys(newUidData).length > 0) {
        prefillForm(newUidData);
      }
    },
    { immediate: true }
  );

  /* ------------------ Prefill from uidData ------------------ */
  function prefillForm(uidData) {
    // Overwrite if the User says he has no UID, or if it's a different one than before
    if (uidData.uid === 'no-uid') {
      form.value.uid = '';
    } else if (uidData.uid && uidData.uid !== form.value.uid) {
      form.value = {
        uid: uidData.uid || '',
        uidErgaenzung: uidData.uidErgaenzung || '',
        name: uidData.vorname || '',
        surname: uidData.nachname || '',
        password: '',
        company: {
          name: uidData.firmenname || '',
          label: uidData.firmenLabel || '',
          email: props.company?.email || '',
          website: props.company?.website || '',
          phone: props.company?.phone || '',
          locations: [
            {
              postcode_id: null,
              address: uidData.strasse || '',
              postcode: uidData.plz || '',
              city: uidData.ort || '',
              headquarter: 1,
              state_code: uidData.state_code || '',
            },
          ],
        },
        locale: 'de-CH',
      };
    }
  }

  /* ------------------ Submission via @tanstack/vue-query ------------------ */
  const createUserWithCompanyMutation = useMutation({
    mutationFn: (payload) => authApi.post('/users/create-with-company', payload, { notification: false }),
    onSuccess: (response) => {
      const { data } = response;
      const content = data?.content;
      if (!content) return;

      if (content.valid) {
        store.commit('setUser', content.user, { root: true });
        setCompanyInStore(content);
        window.dataLayer?.push({ event: 'register_company', email: content.user.email });

        errors.value = {};
        emit('next-step');
      } else {
        notification.warning($t('general.error'));
      }
    },
    onError: (error) => {
      if (error.response && error.response.status === 422) {
        errors.value = error.response.data.errors || {};
        notification.warning('Please correct the errors before proceeding.');
      } else {
        notification.warning($t('general.error'));
      }
    },
    onSettled: () => {
      isSubmitting.value = false;
    },
  });

  function submitForm() {
    errors.value = {};
    isSubmitting.value = true;
    createUserWithCompanyMutation.mutate(form.value);
  }

  function setCompanyInStore(data) {
    store.commit('company/setInitialCompanyData', data.company);
    store.commit('setCompanies', data.employees);
    store.commit('setSelectedCompany', data.company.id);
  }

  /* ------------------ Icon Logic: Name & Surname ------------------ */
  const nameIcon = computed(() => {
    return form.value.name.length > 0 ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const nameIconClass = computed(() => {
    return form.value.name.length > 0 ? 'text-lg text-green-500' : 'text-base';
  });

  const surnameIcon = computed(() => {
    return form.value.surname.length > 0 ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const surnameIconClass = computed(() => {
    return form.value.surname.length > 0 ? 'text-lg text-green-500' : 'text-base';
  });

  /* ------------------ City/Postcode Logic ------------------ */
  const cityOptions = ref([]);
  const selectedCity = ref(null);

  const locationData = computed(() => form.value.company.locations[0]);

  onMounted(() => {
    store.dispatch('getPostcodes');
    checkExistingPostcode();
  });

  watch(
    () => store.state.main.postcodes,
    () => {
      checkExistingPostcode();
    }
  );

  function checkExistingPostcode() {
    const allPostcodes = store.state.main.postcodes || [];
    if (allPostcodes.length > 0 && locationData.value.postcode) {
      getCityOptions();
      if (locationData.value.city) {
        const match = allPostcodes.find((p) => p.city === locationData.value.city);
        if (match) {
          selectedCity.value = match.id;
        }
      }
    }
  }

  function getCityOptions() {
    const allPostcodes = store.state.main.postcodes || [];
    cityOptions.value = allPostcodes.filter((obj) => obj.zip === locationData.value.postcode);
    if (cityOptions.value.length > 0) {
      selectedCity.value = cityOptions.value[0].id;
    } else {
      selectedCity.value = null;
    }
  }

  watch(selectedCity, (newVal) => {
    if (!newVal) return;
    const found = cityOptions.value.find((obj) => obj.id === newVal);
    if (found) {
      locationData.value.city = found.city;
      locationData.value.postcode_id = found.id;
      locationData.value.state_code = found.state_code;
    }
  });

  const addressIcon = computed(() => {
    if (errors.value['company.locations.0.address']) return ['fal', 'exclamation-circle'];
    return locationData.value.address?.length ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const addressIconClass = computed(() => {
    if (errors.value['company.locations.0.address']) return 'text-base text-red-500';
    return locationData.value.address?.length > 0 ? 'text-lg text-green-500' : 'text-base';
  });

  const postcodeIcon = computed(() => {
    if (errors.value['company.locations.0.postcode']) return ['fal', 'exclamation-circle'];
    return cityOptions.value.length > 0 ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const postcodeIconClass = computed(() => {
    if (errors.value['company.locations.0.postcode']) return 'text-base text-red-500';
    return cityOptions.value.length > 0 ? 'text-lg text-green-500' : 'text-base';
  });

  /* ------------------ Website & Email Logic ------------------ */
  const noWebsite = ref(false);
  const websiteTemp = ref(form.value.company.website || '');
  const websiteValidation = ref(null);

  /* Website URL Checker */
  const urlCheckerMutation = useMutation({
    mutationFn: (payload) => {
      return companyApi.post('/url-checker', payload, {
        notification: false,
        progress: false,
        progressMessage: 'Wir überprüfen Ihre Webseite.',
      });
    },
    onSuccess: (response) => {
      form.value.company.website = response.data.content.url;
      websiteValidation.value = response.data.content.valid;
      errors.value['company.website'] = null;
    },
    onError: () => {
      form.value.company.website = '';
      websiteValidation.value = false;
      errors.value['company.website'] = ['Invalid website URL.'];
    },
  });

  function urlChecker() {
    if (!websiteTemp.value) return;
    urlCheckerMutation.mutate({ websiteTemp: websiteTemp.value });
  }

  /* If user checks "noWebsite", override the website field */
  watch(noWebsite, (val) => {
    if (val) {
      form.value.company.website = 'in-progress';
    } else if (websiteTemp.value) {
      form.value.company.website = websiteTemp.value;
    }
  });

  /* Email Checker */
  const emailLastValue = ref(form.value.company.email);
  const localEmailErrors = ref({});

  const emailCheckMutation = useMutation({
    mutationFn: (payload) => {
      return companyApi.post('companies/email-checker', payload, {
        progress: false,
        progressMessage: 'Wir überprüfen Ihre Email.',
      });
    },
    onError: (error) => {
      console.log('email Checker on Error', error);
      errors.value['company.email'] = ['Invalid email'];
    },
    onSuccess: (response) => {
      console.log('email Checker on Success', response.data);

      if (response.data.notificationType === 'success') {
        localEmailErrors.value = {};
        errors.value['company.email'] = null;
      } else {
        errors.value['company.email'] = ['Invalid email'];
      }
    },
  });

  function checkEmail() {
    if (form.value.company.email && emailLastValue.value !== form.value.company.email) {
      localEmailErrors.value = {};
      emailCheckMutation.mutate({ email: form.value.company.email });
    }
    emailLastValue.value = form.value.company.email;
  }

  /* Icons for Website */
  const websiteIcon = computed(() => {
    if (errors.value['company.website']) {
      return ['fal', 'exclamation-circle'];
    }
    return websiteValidation.value ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const websiteIconClass = computed(() => {
    if (errors.value['company.website']) {
      return 'text-base text-red-500';
    }
    return websiteValidation.value ? 'text-lg text-green-500' : 'text-base';
  });

  /* Icons for Email */
  const emailIcon = computed(() => {
    if (errors.value['company.email']) {
      return ['fal', 'exclamation-circle'];
    }
    return form.value.company.email ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });
  const emailIconClass = computed(() => {
    if (errors.value['company.email']) {
      return 'text-base text-red-500';
    }
    return form.value.company.email ? 'text-lg text-green-500' : 'text-base';
  });

  const phoneIconState = computed(() => {
    if (errors.value['company.phone']) {
      return 'invalid';
    }
    if (form.value.company.phone.length > 0) {
      return 'valid';
    }
    return null;
  });
</script>

<style scoped>
    /* Your combined or custom styles */
</style>
