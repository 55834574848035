<template>
  <div class="relative">
    <input
      id="companyName"
      v-model="props.company.name"
      :placeholder="$t('companyArea.components.contact.companyName.companyName')"
      required
      type="text"
      class="mt-2 border border-gray-400 w-full"
    >
    <font-awesome-icon
      :icon="icon"
      class="absolute right-3 top-5"
      :class="iconClass"
    />
    <!-- Show server-side error if any -->
    <p v-if="props.backendErrors['company.name']" class="text-red-500">
      {{ props.backendErrors['company.name'][0] }}
    </p>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    company: {
      type: Object,
      required: true,
    },
    backendErrors: {
      type: Object,
      default: () => ({})
    }
  });


  const icon = computed(() => {
    // If there's a backend error, show exclamation
    if (props.backendErrors['company.name']) {
      return ['fal', 'exclamation-circle'];
    }
    // If the field is filled, show check, else exclamation
    return props.company.name?.length > 0 ? ['fal', 'check'] : ['fal', 'exclamation-circle'];
  });

  const iconClass = computed(() => {
    if (props.backendErrors['company.name']) {
      return 'text-base text-red-500';
    }
    return props.company.name?.length > 0 ? 'text-lg text-green-500' : 'text-base';
  });
</script>

<style scoped></style>
