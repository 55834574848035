<template>
  <div class="mx-auto px-4 sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl">
    <p class="text-center text-base font-semibold mt-2 mb-5">
      {{ $t('publicArea.registerCompany.steps.uid.title') }}
    </p>
    <div>
      <p>{{ $t('publicArea.registerCompany.steps.uid.description') }}</p>
      <p class="mt-2">
        {{ $t('publicArea.registerCompany.steps.uid.linkLabel') }}
        <a
          href="https://www.uid.admin.ch"
          target="_blank"
          class="text-blue-500 underline"
        >
          https://www.uid.admin.ch
        </a>
      </p>
    </div>

    <!-- Form Submission -->
    <form @submit.prevent="onSubmit">
      <div class="relative">
        <input
          v-model="uid"
          id="uid"
          type="text"
          placeholder="CHE-000.000.000"
          class="border border-gray-400 w-full mt-2 px-2 py-1 rounded focus:ring-2 focus:ring-blue-500"
          @input="clearFieldError"
        />
        <span v-if="uidError" class="text-red-500 text-sm">
          {{ uidError }}
        </span>
      </div>

      <!-- Status Messages -->
      <div v-if="isLoading" class="text-gray-500 mt-2">
        {{ $t('publicArea.registerCompany.steps.uid.loadingData') }}
      </div>
      <div v-else-if="errorMessage" class="text-red-500 mt-2">
        {{ $t('general.error') }}: {{ errorMessage }}
      </div>
      <div v-else-if="uidData?.value?.content?.uid" class="text-green-500 mt-2">
        {{ $t('publicArea.registerCompany.steps.uid.dataLoaded') }}
      </div>

      <div class="flex justify-center mt-3">
        <button
          v-if="!errorMessage"
          class="btn btn-lg btn-primary"
          type="submit"
          :disabled="isLoading"
        >
          {{ isLoading ? $t('general.loading') : $t('publicArea.registerCompany.steps.uid.check') }}
        </button>
        <button
          class="btn btn-lg btn-secondary ml-2"
          @click="emit('next-step', { uid: 'no-uid' })"
          :disabled="isLoading"
        >
          {{ isLoading ? $t('general.loading') : $t('publicArea.registerCompany.steps.uid.nextWithoutUid') }}
        </button>
        <button
          v-if="errorMessage"
          class="btn btn-lg btn-secondary ml-2"
          @click="emit('next-step', { uid: uid })"
          :disabled="isLoading"
        >
          {{ isLoading ? $t('general.loading') : $t('publicArea.registerCompany.steps.uid.nextAnyway') }}
        </button>
      </div>
    </form>
    <div class="mt-5">
      <p class="mt-2">
        {{ $t('publicArea.registerCompany.steps.uid.startRegisterDescription') }}
        <a
          href="https://www.kmu.admin.ch/kmu/de/home/praktisches-wissen/kmu-gruenden/firmengruendung/erste-schritte/selbstaendigkeit-schweiz-leitfaden.html"
          target="_blank"
          class="text-blue-500 underline"
        >
          https://www.kmu.admin.ch
        </a>
      </p>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import companyApi from '../../../connections/company.js';
  import { i18n } from '../../../../main.js';

  const props = defineProps({
    companyProp: {
      type: Object,
      default: () => ({}),
    },
  });
  const emit = defineEmits(['next-step']);

  const LOCAL_STORAGE_KEY = 'uid';

  function getInitialFormValues() {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || { uid: '' };
    } catch {
      return { uid: '' };
    }
  }

  // Reactive state for the UID input, local validation error, and server state
  const uid = ref(getInitialFormValues().uid);
  const uidError = ref(null);
  const uidData = ref(null);
  const isLoading = ref(false);
  const errorMessage = ref(null);

  // Save uid to local storage whenever it changes
  watch(uid, (newVal) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ uid: newVal }));
  });

  // Clears the validation error on user input
  function clearFieldError() {
    uidError.value = null;
    errorMessage.value = null;
  }

  // Validates the uid value and returns an error message if invalid
  function validateUID(value) {
    const uidRegex = /^CHE-\d{3}\.\d{3}\.\d{3}$/;
    if (!uidRegex.test(value)) {
      return i18n.global.t('publicArea.registerCompany.steps.uid.uidFormatError');
    }
    return null;
  }

  // Fetch UID data from the server
  async function fetchUIDDataFromServer(uidValue) {
    try {
      isLoading.value = true;
      errorMessage.value = null;
      // Track the event
      window.dataLayer?.push({
        event: 'register_company_ui',
        uid: uidValue,
      });
      const { data } = await companyApi.post('get-uid-register-data', { uid: uidValue });
      console.log('data:', data);
      console.log('data.notification:', data.notification);
      uidData.value = data;
      if (!uidData.value.content.uid) {
        errorMessage.value = data.notification;
        return;
      }
      emit('next-step', uidData.value.content);
    } catch (err) {
      errorMessage.value = err;
    } finally {
      isLoading.value = false;
    }
  }

  // Form submission handler: first perform client-side validation before fetching data
  function onSubmit() {
    uidError.value = null;
    uid.value = uid.value.trim();
    const validationError = validateUID(uid.value);
    if (validationError) {
      uidError.value = validationError;
      return;
    }

    fetchUIDDataFromServer(uid.value);
  }
</script>

<style scoped>
    /* Add custom styles if needed */
</style>
