<template>
  <layout>
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold text-center mt-6">{{ $t('publicArea.registerCompany.registerCompany.becomePartner') }}</p>
      <p class="text-center text-xs sm:text-sm mt-2">{{ $t('publicArea.registerCompany.registerCompany.registerCompany') }}</p>
    </template>

    <template v-slot:filters>
      <div class="flex justify-center mt-4">
        <info-circle :color="step >= 1 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(1)">1</button></info-circle>
        <info-circle :color="step >= 2 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(2)">2</button></info-circle>
        <info-circle :color="step >= 3 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(3)">3</button></info-circle>
        <info-circle :color="step >= 4 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(4)">4</button></info-circle>
        <info-circle :color="step >= 5 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(5)">5</button></info-circle>
        <info-circle v-if="step > 1 && !websiteUrl" :color="step >= 6 ? 'bg-black text-white' : 'bg-white'" class="mr-4 mb-6"><button @click="tryGoToStep(6)">6</button></info-circle>
      </div>
    </template>

    <div class="sm:panel">
      <div>
        <component :is="currentComponent" :company="company" :uid-data="uidData"  @next-step="goToNextStep" />
      </div>
    </div>
  </layout>
</template>

<script>
  import { shallowRef } from 'vue';
  import Area from './steps/Area.vue';
  import TermsOfService from './steps/TermsOfService.vue';
  import RegisterUserWithCompany from './steps/RegisterUserWithCompany.vue';
  import Uid from './steps/Uid.vue';
  import Layout from '../../Layout.vue';
  import Classification from './steps/Classification.vue';
  import InfoCircle from '../../snippets/InfoCircle.vue';
  import store from '../../store/mainStore';
  import WebsiteSubscription from './steps/WebsiteSubscription.vue';

  const UidComponent = shallowRef(Uid);
  const RegisterLayoutWithCompanyComponent = shallowRef(RegisterUserWithCompany);
  const ClassificationComponent = shallowRef(Classification);
  const AreaComponent = shallowRef(Area);
  const TosComponent = shallowRef(TermsOfService);
  const WebsiteSubscriptionComponent = shallowRef(WebsiteSubscription);

  export default {
    name: 'RegisterCompany',
    components: {
      InfoCircle,
      Layout,
      RegisterLayoutWithCompanyComponent,
      ClassificationComponent,
      AreaComponent,
    },
    data() {
      return {
        step: 1,
        uidData: null,
        menu: this.$router.options.routes.filter(obj => obj.meta.layout === 'public'),
        currentComponent: UidComponent,
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      companyId() {
        return store.state.main.companies[0]?.company_id;
      },
      websiteUrl() {
        return this.company && this.company.websites ? this.company.websites[0].url : undefined;
      },
    },
    mounted() {
      if (this.$route.query.step) {
        this.step = parseInt(this.$route.query.step);
        this.goToStep();
      } else {
        this.setStepQuery();
      }
    },
    methods: {
      goToNextStep(data) {
        if (this.step > 5 || (this.step === 5 && this.websiteUrl)) {
          this.$router.push({ name: 'PendingLeads', params: { company_id: this.companyId } });
        }

        if (data) {
          this.uidData = data; // Store the emitted data
        }
        this.step++;
        this.goToStep();
      },
      tryGoToStep(step) {
        if (this.step === 1 && step === 2 && this.uidData) {
          this.step = step;
          this.goToStep();
        }
        if (this.step === 2 && step === 1 ) {
          this.step = step;
          this.goToStep();
        }
      },
      goToStep: function () {
        switch (this.step) {
          case 1:
            this.currentComponent = UidComponent;
            break;
          case 2:
            this.currentComponent = RegisterLayoutWithCompanyComponent;
            break;
          case 3:
            this.currentComponent = ClassificationComponent;
            break;
          case 4:
            this.currentComponent = AreaComponent;
            break;
          case 5:
            this.currentComponent = TosComponent;
            break;
          case 6:
            this.currentComponent = WebsiteSubscriptionComponent;
            break;
          case 7:
            this.$router.push({ name: 'PendingLeads', params: { company_id: this.companyId } });
            break;
        }
      },
      setStepQuery: function (data) {
        let currentQueryParams = { ...this.$route.query };
        currentQueryParams.step = this.step;
        this.$router.push({ path: '/register-company', query: currentQueryParams });
      },
    },
  };
</script>

<style scoped>

</style>
