<template>
  <div class="grid grid-cols-5 gap-4">
    <!-- Address -->
    <div class="col-span-5">
      <div class="relative space-y-1">
        <label for="address" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyCity.street') }}
        </label>
        <input
          id="address"
          v-model="props.company.locations[props.locationIndex].address"
          class="border border-gray-400 w-full"
          required
          type="text"
        >
        <font-awesome-icon
          :icon="addressIcon"
          class="absolute right-3 top-9"
          :class="addressIconClass"
        />
        <p v-if="props.backendErrors[`company.locations.${props.locationIndex}.address`]" class="text-red-500">
          {{ props.backendErrors[`company.locations.${props.locationIndex}.address`][0] }}
        </p>
      </div>
    </div>

    <!-- Postcode -->
    <div class="col-span-1">
      <div class="relative space-y-0.5">
        <label for="postcode" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyCity.zip') }}
        </label>
        <input
          id="postcode"
          v-model="props.company.locations[props.locationIndex].postcode"
          class="border border-gray-400 w-full"
          required
          type="text"
          @input="getCityOptions"
        >
        <font-awesome-icon
          :icon="postcodeIcon"
          class="absolute right-3 top-9"
          :class="postcodeIconClass"
        />
        <p v-if="props.backendErrors[`company.locations.${props.locationIndex}.postcode`]" class="text-red-500">
          {{ props.backendErrors[`company.locations.${props.locationIndex}.postcode`][0] }}
        </p>
      </div>
    </div>

    <!-- City Dropdown -->
    <div class="col-span-4">
      <div class="relative space-y-0.5">
        <label for="city" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyCity.city') }}
        </label>
        <select
          id="city"
          v-model="selected"
          class="border border-gray-400 w-full"
          required
        >
          <option
            v-if="cityOptions.length === 0"
            :value="null"
            class="text-gray-600"
          >
          </option>
          <option
            v-for="option in cityOptions"
            :key="'cityOption' + option.id"
            :value="option.id"
          >
            {{ option.city }}
          </option>
        </select>
        <p v-if="props.backendErrors[`company.locations.${props.locationIndex}.city`]" class="text-red-500">
          {{ props.backendErrors[`company.locations.${props.locationIndex}.city`][0] }}
        </p>
      </div>
    </div>

    <!-- Optional Email -->
    <div v-if="!props.register" class="col-span-5">
      <div class="relative space-y-0.5">
        <label for="email" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyCity.email') }}
        </label>
        <input
          id="email"
          v-model="props.company.locations[props.locationIndex].email"
          class="border border-gray-400 w-full"
          type="email"
        >
      </div>
    </div>

    <!-- Optional Phone -->
    <div v-if="!props.register" class="col-span-5">
      <div class="relative space-y-0.5">
        <label for="phone" class="block text-gray-700">
          {{ $t('companyArea.components.contact.companyCity.phone') }}
        </label>
        <input
          id="phone"
          v-model="props.company.locations[props.locationIndex].phone"
          class="border border-gray-400 w-full"
          type="text"
        >
      </div>
    </div>
  </div>
</template>


<script setup>
  import { ref, computed, watch, onBeforeMount } from 'vue';
  import store from '../../../store/mainStore';

  // Props
  const props = defineProps({
    company: {
      type: Object,
      required: true
    },
    locationIndex: {
      type: Number,
      default: 0
    },
    register: {
      type: Boolean,
      default: false
    },
    backendErrors: {
      type: Object,
      default: () => ({})
    }
  });

  // Local state
  const cityOptions = ref([]);
  const selected = ref(null);

  /**
   * Access the location data more succinctly
   */
  const locationData = computed(() => {
    return props.company.locations[props.locationIndex];
  });

  // On mount, we dispatch postcodes to store
  onBeforeMount(() => {
    store.dispatch('getPostcodes');
    checkData();
  });

  // Re-check data if postcodes change
  watch(
    () => store.state.main.postcodes,
    () => {
      checkData();
    }
  );

  function checkData() {
    if (store.state.main.postcodes && store.state.main.postcodes.length > 0) {
      if (locationData.value.postcode) {
        getCityOptions();
        // If city is already known, set selected
        if (locationData.value.city) {
          const match = store.state.main.postcodes.find(
            (p) => p.city === locationData.value.city
          );
          if (match) {
            selected.value = match.id;
          }
        }
      }
    }
  }

  function getCityOptions() {
    const allPostcodes = store.state.main.postcodes || [];
    cityOptions.value = allPostcodes.filter(
      (obj) => obj.zip === locationData.value.postcode
    );
    if (cityOptions.value.length > 0) {
      selected.value = cityOptions.value[0].id;
    } else {
      selected.value = null;
    }
  }

  // When selected changes, update city, etc.
  watch(selected, (newVal) => {
    console.log('selected changed', newVal);
    if (newVal) {
      console.log('selected changed', newVal);
      const found = cityOptions.value.find((obj) => obj.id === newVal);
      console.log('found', found);
      if (found) {
        locationData.value.city = found.city;
        locationData.value.postcode_id = found.id;
        locationData.value.state_code = found.state_code;
      }
    }
  });

  /**
   * Icons
   */
  const addressIcon = computed(() => {
    // If there's a backend error, show exclamation
    if (props.backendErrors[`company.locations.${props.locationIndex}.address`]) {
      return ['fal', 'exclamation-circle'];
    }
    return locationData.value.address?.length
      ? ['fal', 'check']
      : ['fal', 'exclamation-circle'];
  });
  const addressIconClass = computed(() => {
    if (props.backendErrors[`company.locations.${props.locationIndex}.address`]) {
      return 'text-base text-red-500';
    }
    return locationData.value.address?.length > 0
      ? 'text-lg text-green-500'
      : 'text-base';
  });

  const postcodeIcon = computed(() => {
    if (props.backendErrors[`company.locations.${props.locationIndex}.postcode`]) {
      return ['fal', 'exclamation-circle'];
    }
    // Show check if cityOptions found
    return cityOptions.value.length > 0
      ? ['fal', 'check']
      : ['fal', 'exclamation-circle'];
  });
  const postcodeIconClass = computed(() => {
    if (props.backendErrors[`company.locations.${props.locationIndex}.postcode`]) {
      return 'text-base text-red-500';
    }
    return cityOptions.value.length > 0
      ? 'text-lg text-green-500'
      : 'text-base';
  });
</script>

<style scoped></style>
